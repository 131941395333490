var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white-900 rounded px-5 pt-6 pb-4 relative"},[_c('shortlist-header',{attrs:{"modal-name":"add-shortlist-modal","title":"Add to shortlist"}}),(false)?_c('div',{staticClass:"max-w-290 mx-auto my-16 text-center"},[_c('h4',{staticClass:"text-black-900 text-lg font-medium mb-3"},[_vm._v(" No shortlists created ")]),_c('p',{staticClass:"text-black-600 text-base"},[_vm._v(" The book will be saved to the main shortlist. You can create your own shortlist to divide books into genres ")])]):_c('div',{staticClass:"my-5"},[_c('ul',{staticClass:"border border-grey-500 divide-y divide-grey-500 rounded"},_vm._l((_vm.shortlists),function(item){return _c('li',{key:item.id,staticClass:"\n          flex\n          items-center\n          justify-between\n          py-4\n          pl-6\n          pr-4\n          text-black-900 text-base\n          font-medium\n          first-before-rounded-t\n          last-before-rounded-b\n          relative\n          cursor-pointer\n          before:empty-content\n          before:absolute\n          before:z-10\n          before:-m-px\n          before:inset-0\n          before:border\n          before:border-opacity-0\n          before:pointer-events-none\n          before:border-orange-900\n          hover:bg-grey-400\n        ",class:{
          'before:border-opacity-100 bg-red-300 text-orange-900':
            _vm.selectedShortlist.shortlistIds.includes(item.id),
        },on:{"click":function($event){return _vm.toggleShortlist(item.id)}}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('svg',{class:[
            _vm.selectedShortlist.shortlistIds.includes(item.id)
              ? 'opacity-100'
              : 'opacity-0' ],attrs:{"width":"28","height":"28","viewBox":"0 0 28 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"14","cy":"14","r":"14","fill":"#DB603B"}}),_c('path',{attrs:{"d":"M8 15L13.0526 19L20 9","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])}),0)]),_c('div',{staticClass:"flex justify-between items-center"},[_c('button',{staticClass:"\n        flex\n        justify-between\n        items-center\n        text-black-900 text-base\n        font-medium\n        hover:text-orange-900\n      ",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.show('shortlist-modal', {
          component: 'create-shortlist-modal',
        })}}},[_c('svg',{staticClass:"mr-2",attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 1V11M11 6L1 6","stroke":"currentColor","stroke-width":"1.6","stroke-linecap":"round"}})]),_c('span',[_vm._v("Create new shortlist")])]),_c('base-button',{staticClass:"ml-3 py-3 max-w-164 w-full",attrs:{"colorScheme":"orange"},nativeOn:{"click":function($event){return _vm.handleSave.apply(null, arguments)}}},[_c('span',{staticClass:"font-normal text-sm"},[_vm._v(" Save ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }