<template>
  <div class="bg-white-900 rounded px-5 pt-6 pb-4 relative">
    <!-- scrollbar-thin scrollbar-thumb-grey-700 scrollbar-track-grey-400 -->
    <shortlist-header
      modal-name="add-shortlist-modal"
      title="Add to shortlist"
    />
    <div v-if="false" class="max-w-290 mx-auto my-16 text-center">
      <h4 class="text-black-900 text-lg font-medium mb-3">
        No shortlists created
      </h4>
      <p class="text-black-600 text-base">
        The book will be saved to the main shortlist. You can create your own
        shortlist to divide books into genres
      </p>
    </div>
    <div v-else class="my-5">
      <ul class="border border-grey-500 divide-y divide-grey-500 rounded">
        <li
          v-for="item in shortlists"
          :key="item.id"
          class="
            flex
            items-center
            justify-between
            py-4
            pl-6
            pr-4
            text-black-900 text-base
            font-medium
            first-before-rounded-t
            last-before-rounded-b
            relative
            cursor-pointer
            before:empty-content
            before:absolute
            before:z-10
            before:-m-px
            before:inset-0
            before:border
            before:border-opacity-0
            before:pointer-events-none
            before:border-orange-900
            hover:bg-grey-400
          "
          :class="{
            'before:border-opacity-100 bg-red-300 text-orange-900':
              selectedShortlist.shortlistIds.includes(item.id),
          }"
          @click="toggleShortlist(item.id)"
        >
          <span>
            {{ item.name }}
          </span>
          <svg
            :class="[
              selectedShortlist.shortlistIds.includes(item.id)
                ? 'opacity-100'
                : 'opacity-0',
            ]"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="14" cy="14" r="14" fill="#DB603B" />
            <path
              d="M8 15L13.0526 19L20 9"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </li>
      </ul>
    </div>
    <div class="flex justify-between items-center">
      <button
        type="button"
        class="
          flex
          justify-between
          items-center
          text-black-900 text-base
          font-medium
          hover:text-orange-900
        "
        @click="
          $modal.show('shortlist-modal', {
            component: 'create-shortlist-modal',
          })
        "
      >
        <svg
          class="mr-2"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 1V11M11 6L1 6"
            stroke="currentColor"
            stroke-width="1.6"
            stroke-linecap="round"
          />
        </svg>
        <span>Create new shortlist</span>
      </button>
      <base-button
        colorScheme="orange"
        class="ml-3 py-3 max-w-164 w-full"
        @click.native="handleSave"
      >
        <span class="font-normal text-sm"> Save </span>
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseButton from "@/components/utils/Button.vue";
import ShortlistModalHeader from "@/components/utils/ShortlistModalHeader.vue";
import {
  FETCH_SHORTLISTS,
  TOGGLE_SHORTLIST_ID,
  STORE_SELECTED_SHORTLIST,
} from "@/store/actions.type";

export default {
  name: "AddShortlistModal",
  components: {
    "base-button": BaseButton,
    "shortlist-header": ShortlistModalHeader,
  },
  mounted() {
    this.$store.dispatch(FETCH_SHORTLISTS);
  },
  computed: {
    ...mapGetters(["shortlists", "selectedShortlist"]),
  },
  methods: {
    toggleShortlist(shortlistId) {
      this.$store.dispatch(TOGGLE_SHORTLIST_ID, { shortlistId });
    },
    async handleSave() {
      await this.$store.dispatch(STORE_SELECTED_SHORTLIST);
      this.$modal.hide("shortlist-modal");
    },
  },
};
</script>
